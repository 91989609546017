.projects{
    padding: 8rem 0;
   
    .item__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background: var(--color-beige);
        padding: 1rem;
     .head__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        p{
            font-size: 1.85em;
            font-weight: 700;
            color: var(--muradiye-gold-color);
        }
     }
     .sub__head__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        p{
            font-size: 1.15em;
            font-weight: 600;
            color:var(--cool-grey) ;
        }
     }
     .content__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        p{
            font-size: 1em;
            font-weight: 500;
            color: var(--cool-grey);
            a{
                color: var(--sea-blue);
                font-weight: 600;
                &:hover{
                    letter-spacing: 0.05em;
                    transition: 0.3s ease-in-out;
                }
            }
        }
        .information{
            font-size: 1em;
            font-weight: 600;
            color: var(--sea-blue);
        }
       

     }
    }
}