@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap");

$color-white: #fefefe;
$color-black: #000000;
:root {
  --font-family: "Playfair Display", serif;
  --font-roboto: "Roboto", sans-serif;
  --font-lora: "Lora", sans-serif;
  --font-open-sans: "Open Sans", sans-serif;
  --font-montserrat: "Montserrat", sans-serif;
  --font-Manrope: "Manrope", sans-serif;
  --font-header: "Berkshire Swash", cursive;

  --gradient-bar: linear-gradient(103.22deg, #cf8862 -13.86%, #8c8372 99.55%);
  --gradient-gold-bg: linear-gradient(159deg, #b08d5b 0%, #f7f0e1 70%);
  --gradient-hover: linear-gradient(103.22deg, #8c8372 -13.86%, #cf8862 99.55%);

  --color-bg-dark: #808c74;
  --color-bg-light: rgb(229, 233, 233);
  --color-beige: rgb(247, 247, 247);
  --cool-blue: rgb(0, 123, 255);
  --cool-grey: rgb(108, 117, 125);
  --sea-blue: rgb(23, 162, 184);
  --pastel-blue: rgb(111, 163, 239);
  --dark-teal: rgb(0, 77, 64);
  --cobalt-blue: rgb(0, 51, 160);
  --sweet-beige:#fff8ee;
  --sweet-blue:#f0fffc;
  --muradiye-navbar-color: rgb(148, 143, 132);
  --muradiye-gold-color: #d2b582;
  --muradiye-light-gold-color: #e9d7b2;

  --primary-color-dark: #efdfcc;
  --primary-color-light: #66796b;
  --secondary-color-dark: #d4c2ad;
  --secondary-color-light: #ba8e7a;
  --success-color-dark: #66796b;
  --success-color-light: #66796b;
  --success-color-light2: #9cbcb7;
  --info-color-dark: #45718c;
  --info-color-light: #45718c;
  --warning-color-dark: #f2d95c;
  --warning-color-light: #f2d95c;
  --danger-color-dark: #d97236;
  --danger-color-light: #d97236;
  --text-color-dark: #d9d9d9;
  --text-color-light: #8c8c8c;
  --text-color-silent: #999999;
  --small-text-color-dark: #ebe2d5;
  --small-text-color-light: #b6b7b2;
  --head-color-dark: #a6a6a6;
  --head-color-light: #585959;
  --sub-head-color-dark: #bfa98e;
  --sub-head-color-light: #594834;
  --color-black: #444444;
  --color-white: #fefefefe;
  --color-white-half-opacity: rgba(254, 254, 254, 0.75);
  --color-dark-black: #0d0d0d;
  --color-pure-white: #ffffff;
  --primary-color-hover-dark: #555555;
  --primary-color-hover-light: #555555;
  --secondary-color-hover-dark: #555555;
  --secondary-color-hover-light: #555555;
  --info-color-hover-dark: #555555;
  --info-color-hover-light: #555555;
  --success-color-hover-dark: #555555;
  --success-color-hover-light: #555555;
  --warning-color-hover-dark: #555555;
  --warning-color-hover-light: #555555;
  --danger-color-hover-dark: #555555;
  --danger-color-hover-light: #555555;
}
