.projects {
  padding: 8rem 0;
}
.projects .item__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--color-beige);
  padding: 1rem;
}
.projects .item__container .head__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.projects .item__container .head__container p {
  font-size: 1.85em;
  font-weight: 700;
  color: var(--muradiye-gold-color);
}
.projects .item__container .sub__head__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.projects .item__container .sub__head__container p {
  font-size: 1.15em;
  font-weight: 600;
  color: var(--cool-grey);
}
.projects .item__container .content__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.projects .item__container .content__container p {
  font-size: 1em;
  font-weight: 500;
  color: var(--cool-grey);
}
.projects .item__container .content__container p a {
  color: var(--sea-blue);
  font-weight: 600;
}
.projects .item__container .content__container p a:hover {
  letter-spacing: 0.05em;
  transition: 0.3s ease-in-out;
}
.projects .item__container .content__container .information {
  font-size: 1em;
  font-weight: 600;
  color: var(--sea-blue);
}/*# sourceMappingURL=projects.css.map */