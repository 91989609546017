.donate {
  padding: 10rem 10rem;
  background: url("https://muradiyekulturdernegi.org/assets/donate_1");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: scaleX(-1);
  .donate__container {
    transform: scaleX(-1);
    background: var(--color-white-half-opacity);
    border-radius: 1rem;
    padding: 8rem 2rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    .header__container {
      text-align: center;
      padding: 1rem 4rem;
      margin: 1rem 0rem;
      background: var(--color-white-half-opacity);
      border-radius: 1rem;
      width: 68%;

      p {
        font-size: 2em;
        font-weight: 600;
        color: var(--head-color-light);
      }
    }
    .content__container {
      padding: 4rem 4rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      column-gap: 2rem;
      background: var(--color-white-half-opacity);
      border-radius: 1rem;
      p {
        font-size: 1.25em;
        font-weight: 600;
        color: var(--text-color-light);
        span {
          color: var(--sea-blue);
         
        }
      }
      .logo__container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        img{
          width: 256px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .donate {
    padding: 10rem 2rem;
    .donate__container {
      .content__container {
        .logo__container {
          img {
            max-width: 256px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 520px) {
  .donate {
    padding: 10rem 2rem;
    .donate__container {
      padding: 2rem;
      
      .content__container {
        padding: 3rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 2rem;

        .logo__container {
          img {
            max-width: 128px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 770px) {
  .donate{
    .donate__container{
      .header__container {
        width: 100%;
        padding: 1rem;
        p {
          font-size: 1.75em;
        }
      }
      .content__container {
        flex-direction: column;
        row-gap: 2rem;
      }
    }
  }
}
