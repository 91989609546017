.container__slider {
  height: 100vh;
  padding: 2rem 0;
  .carousel.carousel-slider {
    height: 80vh;
  }

  .section__carousel {
    max-height: 80vh;

    .carousel {
      .slide {
        img {
          opacity: 1;
          object-fit: inherit;
        }
      }
      .carousel-status {
        display: none;
      }
      .control-arrow {
        top: 0;
        font-size: 26px;
        bottom: 0;
        margin-top: 0;
        padding: 10px;
        background: none;
        &:hover {
          background: none;
        }
      }
      .thumbs-wrapper {
        margin: -5rem 2rem;
        overflow: hidden;
      }
      .thumb {
        transition: border 0.15s ease-in;
        display: inline-block;
        margin-right: 6px;
        white-space: nowrap;
        overflow: hidden;
        border: none;
        padding: 0;
        border-radius: 5px;
        width: 60px !important;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
          rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        opacity: 0.75;
        img {
          vertical-align: middle;
          object-fit: cover;
          width: 60px;
          height: 40px;
        }
        &:hover {
          opacity: 1;
          transition: 0.25s ease-in-out;
        }
      }
      .carousel-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--muradiye-gold-color);
        position: absolute;
        top: 0;
        left: 30%;
        padding: 0.25rem 2.25rem;
        width: 40%;
        text-align: left;
        transition: 0.5s ease-in;
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        .header__container {
          p {
            text-align: left;
            font-family: var(--font-header);
            font-size: 2em;
            line-height: 60px;
            letter-spacing: 0.15rem;
            color: var(--color-white);
          }
        }
        .content__container {
          p {
            color: rgb(129, 125, 116);
            font-family: var(--font-family);
            text-align: left;
            font-size: 1.5em;
            line-height: 25px;
            font-weight: 500;
            color: var(--cool-grey);
            position: relative;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .container__slider {
    height: 72vh;
    .carousel.carousel-slider {
      height: 64vh;

      img {
        max-height: 64vh;
        object-fit: cover!important;
      }
    }
  }
  .section__carousel {
    max-height: 64vh;
    .carousel-text-container {
      p {
        font-size: 1.5em!important;
        line-height: 40px!important;
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .container__slider {
    height: 54vh;
    .carousel.carousel-slider {
      height: 48vh !important;
    }
    .section__carousel {
      height: 48vh;
      max-height: 48vh;
      .carousel .carousel-text-container .header__container p {
        text-align: left;
        font-family: var(--font-header);
        font-size: 1.15em!important;
        line-height: 25px!important;
      }
    }
  }
}
@media screen and (max-width: 520px) {
  .container__slider {
    height: 40vh;
    .carousel.carousel-slider {
      height: 30vh !important;
    }
    .section__carousel {
      height: 30vh;
      max-height: 30vh;
      .carousel .carousel-text-container .header__container p {
        text-align: left;
        font-family: var(--font-header);
        font-size: 1.15em!important;
        line-height: 25px!important;
      }
    }
  }
}
