
.section{
width: 100%;
display: block;
padding: 4rem 0;
z-index: 99999999;
}
.section__home {
  top: 0;
  margin-top: 0;
  min-height: 150vh;

  .hidden {
    opacity: 0;
  }

}
