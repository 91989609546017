.container__services {
  /*height: 100vh;*/
  background: var(--color-pure-white);
  position: relative;
  z-index: 2;
}
.container__services .post-module,
.container__services .post-module__component {
  max-width: 360px !important;
  min-width: 320px !important;
}
.container__services .services {
  background: var(--color-pure-white);
  padding: 6rem 0;
  position: relative;
  z-index: 2;
  margin: 0;
}
.container__services .services .card__container {
  text-align: center;
  margin: 0 auto;
  padding: 10rem 0;
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 0;
}
.container__services .services .card__container .container__title {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container__services .services .card__container .container__title p {
  font-family: var(--font-header);
  font-size: 4rem;
  font-weight: 600;
  letter-spacing: 0.15em;
  color: var(--muradiye-gold-color);
}
.container__services .services .card__container .container__content {
  padding: 4rem 16rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  -moz-column-gap: 2.75rem;
       column-gap: 2.75rem;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: var(--color-white);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

@media screen and (max-width: 1440px) {
  .container__services .services {
    padding: 2rem;
  }
  .container__services .services .card__container {
    row-gap: 0;
  }
  .container__services .services .card__container .container__content {
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }
}
@media screen and (max-width: 1200px) {
  .container__services .services {
    padding: 2rem 0;
  }
  .container__services .services .card__container .container__content {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .container__services .services {
    padding: 2rem 0;
  }
  .container__services .services .card__container .container__content {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
@media screen and (max-width: 770px) {
  .container__services .services {
    padding: 2rem 0;
  }
  .container__services .services .card__container {
    row-gap: 0;
  }
  .container__services .services .card__container .container__title p {
    font-size: 2.5em;
  }
  .container__services .services .card__container .container__content {
    padding: 2rem 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
    box-shadow: none;
  }
}
@media screen and (max-width: 520px) {
  .container__services .services {
    padding: 2rem 0;
  }
  .container__services .services .card__container {
    row-gap: 0;
  }
  .container__services .services .card__container .container__title p {
    font-size: 1.5em;
  }
  .container__services .services .card__container .container__content {
    padding: 2rem 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
    box-shadow: none;
  }
}/*# sourceMappingURL=services.css.map */