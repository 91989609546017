.modal {
  position: absolute !important;
  width: 24rem !important;
  height: 9rem !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background: white !important;
  padding: 1rem !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  z-index: 999999999 !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.modal h2 {
  font-size: 12px;
}
.modal p {
  font-size: 10px;
}
.modal .btn {
  font-size: 10px;
  padding: 0.25rem 0.5rem;
  margin: 0 0.5rem 0 0;
}
.modal .btn-success {
  background: var(--color-bg-light);
  color: var(--color-dark);
  border: 2px solid var(--color-bg-light);
}
.modal .btn-success:hover {
  background: transparent;
  color: var(--color-dark);
  transition: 0.3s ease-in-out;
}
.modal .btn-danger {
  background: transparent !important;
  color: var(--color-dark);
  border: 2px solid var(--color-bg-light);
}
.modal .btn-danger:hover {
  background: var(--muradiye-light-gold-color);
  color: var(--color-dark);
  transition: 0.3s ease-in-out;
}/*# sourceMappingURL=modal.css.map */