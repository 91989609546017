.institutions__container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 0 4rem 0;
}
.institutions__container .header__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.institutions__container .header__container p {
  font-size: 4em;
  letter-spacing: 0.15em;
  font-weight: 700;
  font-family: var(--font-header);
  color: var(--muradiye-gold-color);
}
.institutions__container .institutions__component {
  width: 100%;
  padding: 4rem 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  background: var(--color-white);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.institutions__container .institutions__component .content__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem 2rem;
}
.institutions__container .institutions__component .content__container .inst_card__container {
  max-width: 350px;
  padding: 4rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 0.5rem;
}
.institutions__container .institutions__component .content__container .inst_card__container .title__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  min-width: 400px;
}
.institutions__container .institutions__component .content__container .inst_card__container .title__container a {
  font-size: 1.05rem;
  font-weight: 600;
  color: var(--cool-grey);
}
.institutions__container .institutions__component .content__container .inst_card__container .img__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.institutions__container .institutions__component .content__container .inst_card__container .img__container img {
  width: 128px;
  border-radius: 50%;
}
.institutions__container .institutions__component .content__container .inst_card__container .img__container img:nth-of-type(1) {
  background-color: var(--small-text-color-dark) !important;
  height: 128px !important;
  -o-object-fit: contain !important;
     object-fit: contain !important;
}
.institutions__container .institutions__component .content__container .inst_card__container .img__container:hover {
  transform: rotateY(360deg);
  transition: 0.75s ease-in-out;
}
.institutions__container .institutions__component .content__container .inst_card__container .text__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.institutions__container .institutions__component .content__container .inst_card__container .text__container .address__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.institutions__container .institutions__component .content__container .inst_card__container .text__container .address__container p {
  font-weight: 500;
  color: var(--cool-grey);
  font-size: 0.95em;
}

@media screen and (max-width: 1720px) {
  .institutions__container .institutions__component .content__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 1024px) {
  .institutions__container .institutions__component .content__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 920px) {
  .institutions__container {
    overflow: hidden;
  }
  .institutions__container .header__container p {
    font-size: 2.5em;
    letter-spacing: 0.15em;
    font-weight: 700;
    font-family: var(--font-header);
    color: var(--muradiye-gold-color);
  }
  .institutions__container .institutions__component {
    box-shadow: none;
    padding: 1rem;
  }
  .institutions__container .institutions__component .content__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  .institutions__container .institutions__component .content__container .inst_card__container {
    max-width: 340px;
  }
}
@media screen and (max-width: 770px) {
  .institutions__container {
    overflow: hidden;
  }
  .institutions__container .institutions__component .content__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  .institutions__container .institutions__component .content__container .inst_card__container {
    max-width: 420px;
  }
}
@media screen and (max-width: 520px) {
  .institutions__container {
    overflow: hidden;
  }
  .institutions__container .header__container p {
    font-size: 1.5em;
    letter-spacing: 0.15em;
    font-weight: 700;
    font-family: var(--font-header);
    color: var(--muradiye-gold-color);
  }
  .institutions__container .institutions__component {
    box-shadow: none;
    padding: 1rem;
  }
  .institutions__container .institutions__component .content__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}/*# sourceMappingURL=institutionComponent.css.map */