.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: var(--color-beige);
  box-shadow: rgb(38, 77, 62) 0px 20px 30px -10px;
  width: 50%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
.popup-content p {
  font-weight: 600;
  color: var(--cool-grey);
}
.popup-content button {
  font-weight: 600;
}/*# sourceMappingURL=popup.css.map */