.container__counter {
  padding: 2rem 4rem;
  width: 100%;
  height: 100%;
  .main__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .header__container {
      text-align: center;
      p {
        font-family: var(--font-header);
        font-size: 3em;
        letter-spacing: 0.25em;
        line-height: 3em;
        color: var(--head-color-light);
      }
    }
  }
  .counter_content__container {
    display: flex;
    flex-direction: column;

    .container__summary {
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: center;
      align-items: center;

      .row__container {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 160px;
        height: 100px;
        padding: 2rem 1rem;
        border-radius: 0.5rem;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
        background-size: cover;
        background-position: center;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 0.5rem; /* Aynı border-radius */
          padding: 2px; /* Border kalınlığı gibi davranır */
          background: var(
            --gradient-gold-bg
          ); /* Border olarak kullanacağımız gradient */
          z-index: 0; /* İçeriklerin altına gelir */
        }
        & > * {
          padding: 1rem;
          position: relative;
          z-index: 1;
          width: 150px;
          height: 90px;
          border-radius: 0.5rem; /* İçeriklerin köşelerini de yuvarla */
          background-color: var(--color-white); /* İçeriği arka plandan ayır */
        }
        .text__container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;
            color: var(--muradiye-gold-color);
            font-size: 2.5em;
            font-weight: 700;
          }
          p {
            color: var(--cool-grey);
            font-size: 1.025em;
            text-transform: capitalize;
            font-weight: 600;
          }
        }
      }
    }
  }
}
