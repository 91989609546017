.legal {
  padding: 8rem 12rem;
  .legal__container {
    padding: 2rem 4rem;
    margin: 2rem;
    background: var(--color-beige);
    height: 100%;
    display: block;
    position: relative;
    font-family: var(--fon-family);

    a {
      color: var(--sub-head-color-dark);
      font-weight: 500;
      &:hover {
        color: var(--sub-head-color-dark);
      }
    }
    h1 {
      text-align: center;
      color: var(--muradiye-gold-color);
    }

    h2,
    h3,
    p {
      text-align: justify;
    }

    p,
    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      color: var(--font-header);
    }

    h2,
    h3,
    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      color: var(--muradiye-gold-color);
      margin-bottom: 10px;
    }
    small {
      color: var(--small-text-color-light);
    }
  }
}
@media screen and (max-width: 1024px) {
  .legal {
    padding: 8rem 4rem;
  }
}
@media screen and (max-width: 520px) {
  .legal {
    padding: 8rem 0;
    .suggestion{
      padding: 0 2rem;
    }
  }
}

