.langDropDownA a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  color: var(--muradiye-gold-color);
  font-weight: 600;
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}

.header {
  border-bottom: 3px solid;
  -o-border-image: linear-gradient(to right, #bfa98e, #efdfcc, #d2b582) 1;
     border-image: linear-gradient(to right, #bfa98e, #efdfcc, #d2b582) 1;
  background: var(--color-pure-white);
  opacity: 95%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 1.5rem 2rem;
  /*box-shadow: 0 2px 5px rgb(119 113 113);*/
  z-index: 1000;
}
.header .showNavbar {
  transition: 0.75s ease !important;
  color: var(--muradiye-gold-color);
}
.header .showNavbar a:hover {
  color: var(--muradiye-navbar-color);
  transition: 0.3s ease-in-out;
}
.header .logoDiv {
  font-size: 1em;
}

.company-title {
  font-size: 1.25em;
  font-weight: 700;
  color: var(--color-black);
}

.menu {
  margin-bottom: 0;
}
.menu .dropdownMenu {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu .dropdownMenu a {
  display: flex;
  align-items: center;
}
.menu .dropdownMenu .dropdown-menu {
  border: none !important;
}
.menu .listItem,
.menu .dropdownMenu,
.menu .showNavbar {
  margin: 0 0.5rem;
}
.menu .listItem a,
.menu .dropdownMenu a,
.menu .showNavbar a {
  font-family: var(--font-family);
  font-size: 1.25em;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: var(--muradiye-gold-color);
}
.menu .listItem a:hover,
.menu .dropdownMenu a:hover,
.menu .showNavbar a:hover {
  color: var(--muradiye-navbar-color);
  transition: 0.3s ease-in-out;
}

.signUp {
  display: flex;
  align-items: center;
}
.signUp svg {
  color: var(--muradiye-gold-color);
}
.signUp svg:hover {
  color: var(--primaryColor);
}
.signUp .languageDropdown {
  color: var(--muradiye-gold-color);
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 60px;
  border-radius: 0.15rem;
}
.signUp .languageDropdown:hover {
  background: var(--secondaryColor);
  color: var(--primary-color-light);
  transition: 0.3s ease;
}
.signUp .languageDropdown:hover svg {
  color: var(--muradiye-navbar-color);
}
.signUp .languageDropdown svg {
  width: 24px;
  height: auto;
  color: var(--muradiye-gold-color);
}
.signUp .languageDropdown .dropdown-menu {
  transform: translate(-100px, 40px) !important;
  padding: 0.5rem 0;
  /*margin: 4.25rem -5.5rem;*/
  border: none;
  width: 4px;
  color: var(--color-white);
}
.signUp .languageDropdown .dropdown-menu a {
  background-color: var(--muradiye-light-color);
  color: var(--muradiye-gold-color);
}
.signUp .languageDropdown .dropdown-menu a:hover {
  background-color: var(--small-text-color-dark);
  color: var(--muradiye-gold-color);
}
.signUp .languageDropdown .dropdown-menu:hover {
  background: var(--color-pure-white);
}

.logoDiv {
  display: flex;
}
.logoDiv a {
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}
.logoDiv .logo {
  width: 25px;
}

.dropdown-menu {
  background: var(--greyColor);
  border: none;
  padding: 1rem 2rem;
  margin: 3rem -1.5rem;
  width: 190px;
}
.dropdown-menu .text {
  margin: 0.5rem 0.25rem;
  width: 190px;
}
.dropdown-menu .text .icon {
  margin-right: 0.5rem;
}

.signUp {
  gap: 0.5rem;
}
.signUp .textAction {
  background: transparent;
  color: var(--primaryColor);
  cursor: pointer;
  font-size: 14px;
  width: 120px;
  text-align: left;
}
.signUp .textAction:hover {
  background: var(--secondaryColor);
  color: var(--whiteColor);
}
.signUp .textAction svg {
  width: 24px;
  height: auto;
}
.signUp .text {
  padding: 3px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
}
.signUp .text:hover {
  background: var(--secondaryColor);
  color: var(--blackColor);
}
.signUp .dropdown {
  width: 60px;
  padding: 6px 4px;
  text-align: center;
}
.signUp .text-login {
  padding: 3px 10px;
  background: red;
  border-radius: 3px;
  color: var(--primaryColor);
  cursor: pointer;
  font-size: 14px;
}
.signUp .text-login:hover {
  color: var(--secondaryColor);
}
.signUp .toggleNavbarIcon {
  background: none;
  font-weight: 600;
  font-size: 25px;
  cursor: pointer;
}

.addBg {
  justify-content: space-evenly;
  background: var(--color-pure-white) !important;
  opacity: 1 !important;
}

@media screen and (max-width: 519px) {
  .header .logoDiv img {
    width: 80px;
  }
}
@media screen and (min-width: 520px) {
  .header .logoDiv img {
    width: 75px;
  }
  .header .signUp .languageBtnContainer {
    padding: 0;
  }
  .header .signUp .text {
    padding: 6px;
    font-size: 16px;
    color: var(--primaryColor);
  }
  .header .signUp .text:hover {
    color: var(--pureWhiteColor);
  }
  .header .signUp .toggleNavbarIcon {
    font-size: 30px;
    cursor: pointer;
  }
  .addBg {
    justify-content: space-around;
    align-items: center;
  }
}
@media screen and (max-width: 992px) {
  .header {
    padding: 1rem;
  }
}
@media screen and (max-width: 840px) and (min-width: 771px) {
  .header {
    padding: 0.5rem;
  }
  .header .logoDiv {
    max-width: 120px !important;
  }
}
@media screen and (min-width: 770px) {
  .header {
    z-index: 9999;
    padding: 1rem 2rem;
  }
  .header .logoDiv {
    font-size: 0.5em;
    max-width: 120px;
  }
  .header .logoDiv img {
    width: 64px;
  }
  .header .navbar .showNavbar {
    top: 0;
  }
  .header .navbar .closeIcon {
    display: none;
  }
  .header .navbar .menu {
    display: flex;
    flex-direction: row;
  }
  .header .navbar .menu .listItem {
    padding: 0.3rem 1rem;
    border-radius: 3px;
  }
  .header .navbar .menu .listItem a {
    color: var(--primaryColor);
    /*text-shadow: 1px 1px 2px rgb(167, 139, 85);*/
    color: var(--muradiye-gold-color);
    font-weight: 500;
  }
  .header .navbar .menu .listItem a:hover {
    color: var(--muradiye-navbar-color);
    transition: 0.3s ease;
  }
  .header .navbar .menu .listItem a:hover a {
    color: var(--whiteColor);
  }
  .header .toggleNavbarIcon {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  .logoDiv a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
    width: 100%;
    min-width: 320px;
    font-size: 1em !important;
  }
  .navbar {
    position: fixed;
    background: var(--secondaryColor);
    width: 80%;
    top: -100rem;
    right: 50%;
    left: 50%;
    transform: translate(-50%);
    border-radius: 10px;
    text-align: center;
    padding: 2rem;
    transition: 0.3s ease;
    z-index: 2000;
  }
  .showNavbar {
    top: 15px;
  }
  .menu {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: end;
    background: var(--color-white);
    padding: 1rem 0 1rem 2rem;
    position: absolute;
    top: 20px;
    right: 75px;
    margin-top: 1px;
    min-width: 210px;
    max-width: 240px;
    border-radius: 5px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  }
  .menu .listItem {
    padding: 0.5rem 2rem;
    border-radius: 10px;
    cursor: pointer;
  }
  .menu .listItem:hover {
    background: var(--primaryColor);
    color: var(--whiteColor);
    transition: 0.3s ease;
  }
}
@media screen and (max-width: 1708px) {
  .header {
    justify-content: space-between;
  }
  .header .logoDiv {
    font-size: 0.85em;
    max-width: 240px;
  }
  .header .logoDiv img {
    /* width: 48px;*/
  }
  .header .navbar .menu .listItem {
    margin: 0 0.25rem;
    padding: 0.3rem;
  }
  .header .navbar .menu .listItem a {
    font-size: 0.95em;
  }
}
@media screen and (max-width: 520px) {
  .navbar {
    margin-top: 1rem;
  }
  .navbar .menu {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: end;
    background: var(--color-white);
    padding: 1rem 0 1rem 2rem;
    position: absolute;
    top: 20px;
    right: 75px;
    margin-top: 1px;
    min-width: 210px;
    max-width: 240px;
    border-radius: 5px;
  }
}
@media screen and (min-width: 1709px) {
  .header {
    padding: 1rem 5rem;
    justify-content: space-between;
  }
  .header .logoDiv,
  .header .company-title {
    font-size: 1em;
  }
  .header .logoDiv {
    min-width: 320px;
  }
}/*# sourceMappingURL=navbar.css.map */