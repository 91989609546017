.gallery {
  padding: 12rem 0;
}

.ReactModal__Overlay--after-open {
  top: 6rem !important;
  width: 80vw !important;
  height: 90vh !important;
  margin: 0 auto !important;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  left: -12rem;
  min-width: 80vw;
}/*# sourceMappingURL=gallery.css.map */