.institutions {
  padding: 12rem 2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  .header__container {
    p {
      font-size: 3em;
      font-family: var(--font-header);
      color: var(--muradiye-gold-color);
      letter-spacing: 0.05em;
      line-height: 4rem;
    }
  }
  .sub__container {
    padding: 2rem 4rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: baseline;
    gap: 0 2rem;
    .content__container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: flex-end;
      align-items: center;

      .img__container {
        width: 100%;
        max-width: 200px;
        max-height: 200px;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
          rgba(0, 0, 0, 0.22) 0px 10px 10px;
        img {
          width: 200px;
          height: 200px;
          object-fit: contain;
          background: transparent;
          border-radius: 50%;
          border: 2px solid transparent;
        }
      }
      .address__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        row-gap: 0.5rem;
        text-align: left;

        .sub__header__container {
          a {
            font-size: 1.25em;
            font-weight: 600;
            color: var(--muradiye-navbar-color);
          }
        }
        .text__container {
          width: 100%;

          p {
            max-width: 320px;
            color: var(--text-color-light);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .institutions {
    .sub__container {
      grid-template-columns: repeat(2, 1fr);
      .content__container {
        .address__container {
          .sub__header__container {
            a {
              font-size: 1.05em;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 770px) {
  .institutions {
    .sub__container {
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem 2rem;
      .content__container {
        .address__container {
          .sub__header__container {
            a {
              font-size: 1.05em;
            }
          }
        }
      }
    }
  }
}
