.card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: none !important;
  position: relative;
  padding: 1rem;
  overflow: hidden;
  border-radius: 8px;
}
.card .title_bg {
  position: relative;
  height: 4rem;
  margin: 1rem 0;
  z-index: 1;
}
.card .title_bg p {
  font-family: var(--font-header);
  font-size: 1.5em;
  color: var(--color-black);
}
.card .title_bg::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10%;
  right: -10%;
  transform: translateY(-50%);
  background: url(https://muradiyekulturdernegi.org/assets/kermes.jpg) center/cover no-repeat;
  height: 4rem;
  margin: 0 auto;
  border-radius: 15px;
  opacity: 0.65;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.card .card__title {
  position: relative;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.card .card__title p {
  padding: 1rem;
  font-family: var(--font-header);
  font-size: 1.5em;
  color: var(--color-black);
}
.card .card__title p:hover {
  transition: 0.3s ease-in;
  letter-spacing: 0.05em;
}
.card .card__img {
  position: relative;
  top: -5.2rem;
  z-index: 0;
  width: -webkit-fill-available;
  height: 8rem;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.card .card__img img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 8rem;
  opacity: 0.65;
}

.post-module {
  max-width: 420px;
  border-radius: 0.25rem;
  position: relative;
  z-index: 1;
  display: block;
  background: var(--color-pure-white);
  min-width: 410px;
  height: 420px;
  transition: box-shadow 0.5s ease, transform 0.5s ease;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.post-module .thumbnail {
  background: transparent;
  height: 300px;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  left: 0;
}
.post-module .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  background: var(--dark-teal);
  width: 55px;
  height: 55px;
  padding: 10px 0;
  border-radius: 50%;
  color: var(--color-pure-white);
  font-weight: 800;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--color-pure-white);
}
.post-module .thumbnail .date .day {
  font-size: 16px;
}
.post-module .thumbnail .date .month {
  font-size: 10px;
  text-transform: uppercase;
}
.post-module .thumbnail img {
  display: block;
  width: 120%;
  transition: transform 0.5s ease;
  max-height: 320px;
  height: -webkit-fill-available;
  -o-object-fit: cover;
     object-fit: cover;
}
.post-module .post-content {
  position: absolute;
  bottom: 0;
  background: var(--color-pure-white);
  width: 100%;
  padding: 30px;
  transition: opacity 0.5s ease;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.post-module .post-content .category {
  position: absolute;
  top: -20px;
  left: 0;
  background: var(--muradiye-gold-color);
  padding: 8px 15px;
  color: var(--color-pure-white);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.post-module .post-content .category::after {
  content: "";
  position: absolute;
  top: 0px;
  right: -20px;
  width: 2rem;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 37px solid var(--muradiye-gold-color);
}
.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: var(--color-black);
  font-size: 26px;
  font-weight: 700;
}
.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: var(--muradiye-navbar-color);
  font-size: 20px;
  font-weight: 400;
}
.post-module .post-content .description {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 1s ease, opacity 1.5s ease;
}
.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: var(--text-color-silent);
}
.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}
.post-module .post-content .post-meta a {
  color: var(--text-color-silent);
  text-decoration: none;
}
.post-module:hover {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.3);
  transform: none;
}
.post-module:hover .category {
  top: -20px;
  padding: 8px 80px;
}
.post-module:hover .thumbnail img {
  transform: scale(1.1);
  opacity: 0.6;
}
.post-module:hover .post-content .description {
  max-height: 200px;
  opacity: 1;
  display: block;
}/*# sourceMappingURL=cards.css.map */