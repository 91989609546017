.dark-bg {
  background: var(--small-text-color-dark) !important;
}
.container__projects {
  background: var(--color-pure-white);
  padding: 0 12rem;
  .projects__component {
    padding: 2rem 0;
    margin-top: 10rem;
    .card__container {
      position: relative;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      .container__title {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-family: var(--font-header);
          font-size: 4rem;
          font-weight: 600;
          letter-spacing: 0.15em;
          color: var(--text-color-dark);
        }
      }

      .container__content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2rem;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media screen and (max-width: 570px) {
  .container__projects {
    padding: 0.5rem 0;
    margin: 45rem 0;
    .projects__component {
      .card__container {
        .container__content {
          padding: 2rem 4rem;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          row-gap: 2rem;
        }
      }
    }
  }
}
