.about-us {
  padding: 7.5rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 0;

  .paragraph__container {
    padding: 1rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    /*border-right: 3px solid var(--muradiye-light-gold-color);
        border-left: 3px solid var(--muradiye-light-gold-color);*/
    .header__container {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      
      color: var(--muradiye-gold-color);
      font-family: var(--font-header);
      font-size: 2.5em;
      p{
        margin-bottom: 0;
      }
    }
    .content__container {
      padding: 0 2rem;
      p {
        font-size: 0.85em;
        font-weight: 500;
        color: var(--text-color-light);
      }
    }
  }
}
/* HTML: <div class="ribbon">Your text content</div> */

.ribbon {
    text-align: center;
    padding: 1rem 0;
    width: 100%;
    min-width: 82rem;
    /* margin: 0 10rem; */
    --d: 0.6em;
    position: relative;
    top: 0;
    inset-inline: calc(0 * var(--d));
    border-bottom: var(--d) solid rgba(0, 0, 0, 0.5333333333);
    line-height: 1.8;
    clip-path: polygon(0 0, 100% 0, 100% calc(96% - var(--d)), calc(100% - var(--d)) 100%, calc(100% - var(--d)) calc(100% - var(--d)), var(--d) calc(100% - var(--d)), var(--d) 100%, 0 calc(96% - var(--d)));
    background-color: var(--color-beige);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

@media screen and (max-width:520px) {
  .about-us{
    padding: 9rem 0;
  }
  
}