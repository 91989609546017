.footer__section {
  background: var(--color-pure-white);
  background: linear-gradient(-18deg, #fff 20%, #e5d7ba 80%);
  padding: 2rem 1rem;
}
.footer__section .back-to-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  border: 3px solid var(--muradiye-gold-color);
  color: var(--muradiye-gold-color);
  background: var(--color-pure-white);
  border-radius: 50%;
  font-size: 1em;
  font-weight: 600;
  padding: 0.5rem;
  right: 2rem;
  z-index: 99999999;
  /* top: 100vh; */
  bottom: 2rem;
  margin-top: 28rem;
  animation: pulse 2s infinite;
}
.footer__section .back-to-top:hover {
  transition: 0.3s ease;
  font-size: 1.05em;
  background: transparent;
  color: var(--muradiye-navbar-color);
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.footer__section .footer-link {
  color: var(--muradiye-navbar-color);
}
.footer__section .footer-link:hover {
  transition: 0.3s ease-in-out;
  color: var(--muradiye-gold-color);
}
.footer__section .footer__section-heading {
  font-family: var(--font-family);
  letter-spacing: 0.15em;
  color: #97907c;
  font-size: 24px;
  line-height: 25px;
  font-weight: 900;
  margin-top: 0.5rem;
  text-align: center;
}

.footer__section-page_container {
  margin: 2rem 12rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-top: 2px solid #97907c;
  border-bottom: 2px solid #97907c;
  box-shadow: inset -4px 3px 54px -10px rgba(210, 181, 130, 0.75);
}
.footer__section-page_container .brand__container {
  font-family: var(--font-family);
  color: #444444;
  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  margin: 0.5rem;
}
.footer__section-page_container .brand__container a img {
  width: auto;
  text-shadow: 0px 0px 5px #638253;
  max-height: 48px;
}

.footer__section-menu_container {
  margin: 2rem 10rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #97907c;
  border-bottom: 2px solid #97907c;
}
.footer__section-menu_container p {
  font-family: var(--font-family);
  color: #444444;
  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  margin: 0.5rem;
}

.footer__section-content {
  display: grid;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  padding: 0 15rem;
}
.footer__section-content h3 {
  color: var(--muradiye-gold-color);
}
.footer__section-content_one, .footer__section-content_two {
  border-right: 2px solid #97907c;
}
.footer__section-content_one .phone, .footer__section-content_two .phone {
  font-family: var(--font-roboto);
}
.footer__section-content_one, .footer__section-content_two, .footer__section-content_three {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  color: #97907c;
  padding: 0 5rem;
  height: 100%;
  max-height: 15rem;
  align-items: flex-start;
  width: 100%;
  max-width: 30rem;
}

.footer__section-bottom_container-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.footer__section-bottom_container-links button,
.footer__section-bottom_container-links a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0.25rem auto;
  background: rgb(148, 143, 132);
  background: linear-gradient(to right, rgb(148, 143, 132) 0%, #dec491 100%);
  color: #fff;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 15px;
  border-radius: 0.5rem !important;
  border: 0;
  outline: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.footer__section-bottom_container-links button i,
.footer__section-bottom_container-links a i {
  margin-left: 0.5rem;
}

.footer__section-copyright_container {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  margin-top: 4.25rem;
}
.footer__section-copyright_container p {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #97907c;
  font-style: italic;
}
.footer__section-copyright_container a:hover {
  color: rgb(180, 163, 123);
}

@media screen and (max-width: 1720px) {
  .footer__section {
    padding: 2rem 6rem;
  }
  .footer__section .back-to-top {
    margin-top: 0;
  }
  .footer__section .footer__section-page_container {
    margin: 2rem 4rem;
    padding: 2rem 6rem;
  }
  .footer__section .footer__section-page_container .brand__container a img {
    max-height: 42px !important;
  }
  .footer__section .footer__section-content {
    display: grid;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 3rem;
         column-gap: 3rem;
    row-gap: 1rem;
    padding: 0 5rem;
    grid-auto-flow: dense;
  }
  .footer__section .footer__section-content_one, .footer__section .footer__section-content_two, .footer__section .footer__section-content_three {
    justify-content: space-around;
    font-size: 15px;
    padding: 0 0rem;
    height: 100%;
    max-height: 20rem;
    align-items: flex-start;
    width: 100%;
    max-width: 22rem;
    padding-right: 3rem;
  }
}
@media screen and (max-width: 1440px) {
  .footer__section {
    padding: 2rem 4rem;
  }
  .footer__section .back-to-top {
    margin-top: 0;
  }
  .footer__section .footer__section-page_container {
    margin: 2rem 0;
    padding: 0;
  }
  .footer__section .footer__section-page_container .brand__container a img {
    max-height: 24px;
  }
  .footer__section .footer__section-content {
    display: grid;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 0;
         column-gap: 0;
    row-gap: 1rem;
    padding: 0;
    grid-auto-flow: dense;
  }
  .footer__section .footer__section-content_one, .footer__section .footer__section-content_two, .footer__section .footer__section-content_three {
    justify-content: space-around;
    font-size: 15px;
    padding: 0 0rem;
    height: 100%;
    max-height: 15rem;
    align-items: flex-start;
    width: 100%;
    max-width: 20rem;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .footer__section {
    padding: 2rem 4rem;
  }
  .footer__section .back-to-top {
    margin-top: 0;
  }
  .footer__section .footer__section-page_container {
    margin: 2rem 0;
  }
  .footer__section .footer__section-page_container .brand__container a img {
    max-height: 24px !important;
  }
  .footer__section .footer__section-content {
    display: grid;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 0;
         column-gap: 0;
    row-gap: 1rem;
    padding: 0;
    grid-auto-flow: dense;
  }
  .footer__section .footer__section-content_one, .footer__section .footer__section-content_two, .footer__section .footer__section-content_three {
    justify-content: space-around;
    font-size: 15px;
    padding: 0 0rem;
    height: 100%;
    max-height: 15rem;
    align-items: flex-start;
    width: 100%;
    max-width: 20rem;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 1120px) {
  .footer__section {
    padding: 2rem 2rem;
  }
  .footer__section .back-to-top {
    margin-top: 0;
  }
  .footer__section .footer__section-content {
    display: grid;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 0;
         column-gap: 0;
    row-gap: 1rem;
    padding: 0;
    grid-auto-flow: dense;
  }
  .footer__section .footer__section-content_one, .footer__section .footer__section-content_two, .footer__section .footer__section-content_three {
    justify-content: space-around;
    font-size: 15px;
    padding: 0 1rem;
    height: 100%;
    max-height: 15rem;
    align-items: flex-start;
    width: 100%;
    max-width: 20rem;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 1024px) {
  .footer__section {
    padding: 2rem 4rem;
  }
  .footer__section .back-to-top {
    margin-top: 0;
  }
  .footer__section .footer__section-page_container {
    margin: 2rem 0;
  }
  .footer__section .footer__section-page_container .brand__container a img {
    max-height: 30px;
  }
  .footer__section .footer__section-content {
    display: grid;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    -moz-column-gap: 0;
         column-gap: 0;
    row-gap: 1rem;
    padding: 0;
    grid-auto-flow: dense;
  }
  .footer__section .footer__section-content_one, .footer__section .footer__section-content_two, .footer__section .footer__section-content_three {
    justify-content: space-around;
    font-size: 15px;
    padding: 0 0rem;
    height: 100%;
    max-height: 15rem;
    align-items: flex-start;
    width: 100%;
    max-width: 20rem;
    padding-right: 1rem;
    border: none;
  }
  .footer__section .footer__section-content_two {
    grid-column: span 2;
  }
}
@media screen and (max-width: 992px) {
  .footer__section {
    padding: 2rem 4rem;
  }
  .footer__section .back-to-top {
    margin-top: 0;
  }
  .footer__section .footer__section-page_container .brand__container a img {
    max-height: 24px;
  }
  .footer__section .footer__section-content {
    display: grid;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    grid-template-columns: repeat(1, 1fr);
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
    row-gap: 0rem;
    padding: 0 4rem;
    grid-auto-flow: dense;
  }
  .footer__section .footer__section-content_one, .footer__section .footer__section-content_two, .footer__section .footer__section-content_three {
    align-items: flex-start;
    justify-content: flex-start;
    border: none;
  }
  .footer__section .footer__section-content_one p, .footer__section .footer__section-content_two p, .footer__section .footer__section-content_three p {
    font-size: 1.1em;
  }
  .footer__section .footer__section-content_two {
    grid-column: span 2;
  }
}
@media screen and (max-width: 770px) {
  .footer__section-page_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
  }
  .footer__section-page_container .brand__container a img {
    width: auto;
    text-shadow: 0px 0px 5px #638253;
    max-height: 24px;
  }
  .footer__section {
    padding: 2rem 2rem;
  }
  .footer__section .back-to-top {
    margin-top: 0;
  }
  .footer__section .footer__section-content {
    display: grid;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    grid-template-columns: repeat(1, 1fr);
    -moz-column-gap: 2rem;
         column-gap: 2rem;
    row-gap: 1rem;
    padding: 0 5rem;
    grid-auto-flow: dense;
  }
  .footer__section .footer__section-content_one, .footer__section .footer__section-content_two, .footer__section .footer__section-content_three {
    border: none;
  }
  .footer__section .footer__section-content_two {
    grid-column: span 2;
  }
}
@media screen and (max-width: 520px) {
  .footer__section {
    padding: 1.5rem 0;
  }
  .footer__section .footer__section-page_container {
    padding: 0.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
    margin: 2rem 0;
  }
  .footer__section .footer__section-page_container .brand__container a img {
    max-height: 24px;
  }
  .footer__section .footer__section-content {
    display: grid;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    grid-template-columns: repeat(1, 1fr);
    -moz-column-gap: 2rem;
         column-gap: 2rem;
    row-gap: 1rem;
    padding: 1rem;
    grid-auto-flow: dense;
  }
  .footer__section .footer__section-content p {
    font-size: 0.95em;
    margin-bottom: 0.5rem;
  }
  .footer__section .footer__section-copyright_container {
    margin-top: 1.25rem;
  }
}/*# sourceMappingURL=footer.css.map */