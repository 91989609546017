.section {
  width: 100%;
  display: block;
  padding: 4rem 0;
  z-index: 99999999;
}

.section__home {
  top: 0;
  margin-top: 0;
  min-height: 150vh;
}
.section__home .hidden {
  opacity: 0;
}/*# sourceMappingURL=home.css.map */