.form-label {
  margin-bottom: 0.5rem;
  color: var(--head-color-light);
  font-weight: 600;
}

.head {
  color: var(--muradiye-gold-color);
  font-size: 1.85em;
  font-weight: 600;
}

input, textarea {
  color: var(--bs-body-color);
  background: var(--color-beige) !important;
  border: none !important;
  outline: none !important;
}
input:focus, input:active, textarea:focus, textarea:active {
  outline: none !important;
  border: 2px solid var(--muradiye-gold-color) !important;
}

.form-control {
  color: var(--bs-body-color);
  background: var(--color-beige) !important;
  border: none;
  outline: none;
  border-color: inherit !important;
  box-shadow: none !important;
}
.form-control:focus, .form-control:active {
  border: 2px solid var(--muradiye-gold-color) !important;
  outline: none !important;
}/*# sourceMappingURL=forms.css.map */