.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .pagination button {
    margin: 0;
    padding: 0.25rem 1rem;
    border-top: 1px dashed var(--muradiye-gold-color);
    border-bottom: 1px dashed var(--muradiye-gold-color);
    border-left: none;
    border-right:none;
    background-color: var(--color-pure-white);
    color: var(--muradiye-navbar-color);
    cursor: pointer;
    &:hover{
        background: var(--color-bg-light);
        color: var(color-dark);
        transition: .3s ease-in;
    }
    &:first-child{
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-left: 1px dashed var(--muradiye-gold-color);
        color:var(--muradiye-gold-color);
    }
    &:last-child{
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border-right: 1px dashed var(--muradiye-gold-color);
        color:var(--muradiye-gold-color)
    }
  }
  
  .pagination button.active {
    background-color: var(--muradiye-gold-color);
    color: var(--color-pure-white);
    
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: var(--color-bg-light);
  }
  