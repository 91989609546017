.langDropDownA {
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem;
    color: var(--muradiye-gold-color);
    font-weight: 600;
    column-gap: 0.25rem;
  }
}

.header {
  .showNavbar {
    transition: 0.75s ease !important;
    color: var(--muradiye-gold-color);
    a {
      &:hover {
        color: var(--muradiye-navbar-color);
        transition: 0.3s ease-in-out;
      }
    }
  }
  border-bottom: 3px solid;
  border-image: linear-gradient(to right, #bfa98e, #efdfcc, #d2b582) 1;
  background: var(--color-pure-white);
  opacity: 95%;

  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 1.5rem 2rem;
  /*box-shadow: 0 2px 5px rgb(119 113 113);*/
  z-index: 1000;
  .logoDiv {
    font-size: 1em;
  }
}
.company-title {
  font-size: 1.25em;
  font-weight: 700;
  color: var(--color-black);
}
.menu {
  margin-bottom: 0;
  .dropdownMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      display: flex;
      align-items: center;
    }
    .dropdown-menu {
      border: none !important;
    }
  }

  .listItem,
  .dropdownMenu,
  .showNavbar {
    margin: 0 0.5rem;
    a {
      font-family: var(--font-family);
      font-size: 1.25em;
      font-weight: 500;
      letter-spacing: 0.05em;
      color: var(--muradiye-gold-color);
      &:hover {
        color: var(--muradiye-navbar-color);
        transition: 0.3s ease-in-out;
      }
    }
  }
}
.signUp {
  svg {
    color: var(--muradiye-gold-color);
    &:hover {
      color: var(--primaryColor);
    }
  }
  display: flex;
  align-items: center;
  .languageDropdown {
    color: var(--muradiye-gold-color);
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 2px;
    width: 60px;
    border-radius: 0.15rem;

    &:hover {
      background: var(--secondaryColor);
      color: var(--primary-color-light);
      transition: 0.3s ease;
      svg {
        color: var(--muradiye-navbar-color);
      }
    }
    svg {
      width: 24px;
      height: auto;
      color: var(--muradiye-gold-color);
    }
    .dropdown-menu {
      transform: translate(-100px, 40px) !important;
      padding: 0.5rem 0;
      /*margin: 4.25rem -5.5rem;*/
      border: none;
      width: 4px;

      a {
        background-color: var(--muradiye-light-color);
        color: var(--muradiye-gold-color);

        &:hover {
          background-color: var(--small-text-color-dark);
          color: var(--muradiye-gold-color);
        }
      }
      &:hover {
        background: var(--color-pure-white);
      }
      color: var(--color-white);
    }
  }
}

.logoDiv {
  display: flex;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
  }
  .logo {
    width: 25px;
  }
}
.dropdown-menu {
  background: var(--greyColor);
  border: none;
  padding: 1rem 2rem;
  margin: 3rem -1.5rem;
  width: 190px;

  .text {
    margin: 0.5rem 0.25rem;
    width: 190px;
    .icon {
      margin-right: 0.5rem;
    }
  }
}
.signUp {
  gap: 0.5rem;
  .textAction {
    background: transparent;
    color: var(--primaryColor);
    cursor: pointer;
    font-size: 14px;
    width: 120px;
    text-align: left;
    &:hover {
      background: var(--secondaryColor);
      color: var(--whiteColor);
    }
    svg {
      width: 24px;
      height: auto;
    }
  }
  .text {
    padding: 3px 10px;

    border-radius: 3px;

    cursor: pointer;
    font-size: 14px;
    text-align: left;
    &:hover {
      background: var(--secondaryColor);
      color: var(--blackColor);
    }
  }
  .dropdown {
    width: 60px;
    padding: 6px 4px;
    text-align: center;
  }

  .text-login {
    padding: 3px 10px;
    background: red;
    border-radius: 3px;
    color: var(--primaryColor);
    cursor: pointer;
    font-size: 14px;
    &:hover {
      color: var(--secondaryColor);
    }
  }
  .toggleNavbarIcon {
    background: none;
    font-weight: 600;
    font-size: 25px;
    cursor: pointer;
  }
}

.addBg {
  justify-content: space-evenly;
  background: var(--color-pure-white) !important;
  opacity: 1 !important;
}

@media screen and (max-width: 519px) {
  .header {
    .logoDiv {
      img {
        width: 80px;
      }
    }
  }
}

@media screen and (min-width: 520px) {
  .header {
    .logoDiv {
      img {
        width: 75px;
      }
    }
    .signUp {
      .languageBtnContainer {
        padding: 0;
      }
      .text {
        padding: 6px;
        font-size: 16px;
        color: var(--primaryColor);
        &:hover {
          color: var(--pureWhiteColor);
        }
      }

      .toggleNavbarIcon {
        font-size: 30px;
        cursor: pointer;
      }
    }
  }
  .addBg {
    justify-content: space-around;
    align-items: center;
  }
}
@media screen and (max-width: 992px) {
  .header {
    padding: 1rem;
  }
}
@media screen and (max-width:840px) and (min-width:771px) {
  .header {
    padding:0.5rem;
    .logoDiv{
      max-width: 120px!important;
    }
}
}
@media screen and (min-width: 770px) {
  .header {
    z-index: 9999;
    padding: 1rem 2rem;
    .logoDiv {
      font-size: 0.5em;
      max-width: 120px;
      img {
        width: 64px;
      }
    }
    .navbar {
      .showNavbar {
        top: 0;
      }

      .closeIcon {
        display: none;
      }
      .menu {
        display: flex;
        flex-direction: row;
        .listItem {
          padding: 0.3rem 1rem;
          border-radius: 3px;
          a {
            color: var(--primaryColor);

            /*text-shadow: 1px 1px 2px rgb(167, 139, 85);*/
            color: var(--muradiye-gold-color);
            font-weight: 500;
            &:hover {
              color: var(--muradiye-navbar-color);
              transition: 0.3s ease;
            }
            &:hover a {
              color: var(--whiteColor);
            }
          }
        }
      }
    }

    .toggleNavbarIcon {
      display: none;
    }
  }
}
@media screen and (max-width: 769px) {
  .logoDiv a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.25rem;
    width: 100%;
    min-width: 320px;
    font-size: 1em!important;
}
  .navbar {
    position: fixed;
    background: var(--secondaryColor);
    width: 80%;
    top: -100rem;
    right: 50%;
    left: 50%;
    transform: translate(-50%);
    border-radius: 10px;
    text-align: center;
    padding: 2rem;
    transition: 0.3s ease;
    z-index: 2000;
  }
  .showNavbar {
    top: 15px;
  }

  .menu {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: end;
    background: var(--color-white);
    padding: 1rem 0 1rem 2rem;
    position: absolute;
    top: 20px;
    right: 75px;
    margin-top: 1px;
    min-width: 210px;
    max-width: 240px;
    border-radius: 5px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

    .listItem {
      padding: 0.5rem 2rem;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background: var(--primaryColor);
        color: var(--whiteColor);
        transition: 0.3s ease;
      }
    }
  }
}


@media screen and (max-width: 1708px) {
  .addBg {
  }
  .header {
    justify-content: space-between;
    .logoDiv {
      font-size: 0.85em;
      max-width: 240px;

      img {
        /* width: 48px;*/
      }
    }
    .navbar {
      .menu {
        .listItem {
          margin: 0 0.25rem;
          padding: 0.3rem;
          a {
            font-size: 0.95em;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 520px) {
  .navbar {
    margin-top: 1rem;
    .menu {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      text-align: end;
      background: var(--color-white);
      padding: 1rem 0 1rem 2rem;
      position: absolute;
      top: 20px;
      right: 75px;
      margin-top: 1px;
      min-width: 210px;
      max-width: 240px;
      border-radius: 5px;
    }
  }
}
@media screen and (max-width:420px) {
  
}
@media screen and (min-width: 1709px) {
  .header {
    padding: 1rem 5rem;
    justify-content: space-between;
    .logoDiv,
    .company-title {
      font-size: 1em;
    }
    .logoDiv{
      min-width: 320px;
    }
  }
}
