.legal {
  padding: 8rem 12rem;
}
.legal .legal__container {
  padding: 2rem 4rem;
  margin: 2rem;
  background: var(--color-beige);
  height: 100%;
  display: block;
  position: relative;
  font-family: var(--fon-family);
}
.legal .legal__container a {
  color: var(--sub-head-color-dark);
  font-weight: 500;
}
.legal .legal__container a:hover {
  color: var(--sub-head-color-dark);
}
.legal .legal__container h1 {
  text-align: center;
  color: var(--muradiye-gold-color);
}
.legal .legal__container h2,
.legal .legal__container h3,
.legal .legal__container p {
  text-align: justify;
}
.legal .legal__container p,
.legal .legal__container li {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: var(--font-header);
}
.legal .legal__container h2,
.legal .legal__container h3,
.legal .legal__container h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: var(--muradiye-gold-color);
  margin-bottom: 10px;
}
.legal .legal__container small {
  color: var(--small-text-color-light);
}

@media screen and (max-width: 1024px) {
  .legal {
    padding: 8rem 4rem;
  }
}
@media screen and (max-width: 520px) {
  .legal {
    padding: 8rem 0;
  }
  .legal .suggestion {
    padding: 0 2rem;
  }
}/*# sourceMappingURL=legalPage.css.map */