:root {
  --padding-base: 3rem;
  --padding-increment: 1rem;
}

.scrolled {
  margin-top: -52rem !important;
  transition: 0.5s ease;
  z-index: 2;
  position: relative;
}
.container__about {
  transition: height 0.3s ease-out;
}
.about {
  overflow: hidden;
  min-height: 42vh;
  padding: 15rem 50rem 18rem 6rem;
  display: flex;
  flex-direction: column;
  background: url(https://muradiyekulturdernegi.org/assets/50880322.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  .header__container {
    p {
      font-family: var(--font-header);
      font-size: 50px;
      line-height: 100px;
      letter-spacing: 0.15rem;
      color: var(--muradiye-gold-color);
    }
  }
  .content__container {
    p {
      color: var(--cool-grey);
      font-family: var(--font-family);
      text-align: left;
      font-size: 15px;
      line-height: 28px;
      font-weight: 500;
      margin-top: 0.75rem;
    }
  }
}

@media screen and (max-width: 770px) {
  .about {
    min-height: 42vh;
    padding: 9rem 5rem 10rem 3rem;
    display: flex;
    flex-direction: column;
    background: url(https://muradiyekulturdernegi.org/assets/50880322.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    .header__container {
      p {
        padding: 0.5rem 0;
        font-size: 42px;
        line-height: 75px;
      }
    }
    .content__container {
      background: var(--color-white-half-opacity);
      padding: 1rem;
      margin-top: 1rem;
      position: relative;
      border-radius: 0.5rem;
      p {
      }
    }
  }
}
@media screen and (max-width:640px) {
  .footer__section {.footer__section-content {
    padding: 0;
}}
}
@media screen and (max-width: 520px) {
  .about {
    padding: 9rem 1rem 1rem 1rem;
    .header__container {
      p {
        padding: 0 0.5rem;
        font-size: 28px;
        line-height: 48px;
      }
    }
    .content__container{
        margin-top:-1rem;
    }
  }
}
@media screen and (min-width: 771px) {
  .about {
    min-height: 42vh;
    padding: 9rem 10rem 3rem 3rem;
    display: flex;
    flex-direction: column;
    background: url(https://muradiyekulturdernegi.org/assets/50880322.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    .header__container {
      p {
        font-size: 44px;
      }
    }
  }
}
@media screen and (min-width: 900px) {
  .about {
    padding: 10rem 10rem 18rem 2rem;
    background-position: bottom;
    .header__container {
      p {
        font-size: 44px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .about {
    padding: 8rem 23rem 15rem 3rem;
    background-position: bottom;
    .header__container {
      p {
        font-size: 44px;
      }
    }
  }
}
@media screen and (min-width: 1120px) {
  .about {
    padding: 10rem 22rem 18rem 5rem;
    background-position: bottom;
    .header__container {
      p {
        font-size: 44px;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .about {
    padding: 12rem 23rem 18rem 4rem;
    background-position: bottom;
    .header__container {
      p {
        font-size: 44px;
      }
    }
  }
}
@media screen and (min-width: 1410px) {
  .about {
    padding: 10rem 45rem 18rem 6rem;
    background-position: bottom;
    .header__container {
      p {
        font-size: 44px;
      }
    }
  }
}
@media screen and (min-width: 1468px) {
  .about {
    padding: 15rem 50rem 18rem 6rem;
    background-position: bottom;
    .header__container {
      p {
        font-size: 44px;
      }
    }
  }
}
