.events {
  padding: 8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.events .filter__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.events .filter__container .action__container {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.events .filter__container .info__container {
  margin-right: 10rem;
}
.events .filter__container .info__container a {
  margin-bottom: 0 !important;
  text-align: right;
  font-size: 12px;
  font-style: italic;
  color: var(--small-text-color-light);
}
.events .filter__container .info__container a:hover {
  color: var(--muradiye-light-gold-color);
  transition: 0.3s ease;
}
.events .filter__container .info__container a svg {
  font-size: 24px;
}
.events .filter__container button,
.events .filter__container select {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: none;
  background-color: var(--color-beige);
  border: none !important;
  color: var(--muradiye-navbar-color);
  border-radius: 5px;
}
.events .filter__container button:hover,
.events .filter__container select:hover {
  background-color: var(--muradiye-navbar-color);
  color: var(--color-white);
}
.events .filter__container .filter-select {
  border-radius: 5px !important;
  padding: 0.5rem 1rem !important;
  border-right: 1rem solid transparent !important;
}
.events .filter__container .filter-select option {
  background-color: var(--color-beige);
  color: var(--muradiye-navbar-color);
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  border: none !important;
  border-color: transparent !important;
}
.events .filter__container .filter-select option:hover {
  background-color: var(--muradiye-navbar-color) !important;
  color: var(--color-white);
}
.events .filter__container .filter-select:focus {
  outline: none;
  border-color: var(--muradiye-navbar-color);
}
.events .event__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.title {
  font-size: 18px;
  margin: 8px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.event__card:hover .title {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
}

/*custom select box*/
.css-ece9u5 {
  border: none !important;
  min-width: 200px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}
.css-ece9u5 label {
  display: none !important;
}

.css-kg5swy {
  display: none !important;
  padding: 0;
}

.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.35rem 2rem !important;
}

.css-1cprn2g-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  font-family: var(--font-family) !important;
  font-size: 18px !important;
  line-height: 1rem !important;
  color: var(--muradiye-navbar-color) !important;
  border-radius: 4px;
  border: none !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: 0 !important;
  border-width: 0 !important;
  border-color: transparent !important;
  background: var(--color-beige) !important;
  color: var(--muradiye-navbar-color) !important;
  z-index: 98;
}

.css-1u6jos5-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--muradiye-navbar-color) !important;
  z-index: 99;
}

.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.5rem 2rem !important;
  z-index: 99;
  font-size: 16px !important;
  margin-top: 0.5rem;
}

.css-1h4bzp1-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 16px !important;
  color: var(--muradiye-navbar-color) !important;
}

.css-1h4bzp1-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: var(--muradiye-light-gold-color) !important;
}

@media screen and (max-width: 1200px) {
  .events {
    margin: 0 auto;
  }
  .events .filter__container {
    justify-content: space-between;
    padding: 0 5rem;
  }
  .events .filter__container .info__container {
    margin-right: 0;
  }
  .events .event__container {
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 768px) {
  .events {
    margin: 0 auto;
  }
  .events .filter__container {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 1rem 6rem;
  }
  .events .filter__container .info__container {
    margin-right: 0;
  }
  .events .event__container {
    justify-content: space-evenly;
  }
}/*# sourceMappingURL=events.css.map */