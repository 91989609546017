.contact {
  padding: 6rem 0;
}
.contact .contact__section-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 8rem 6rem;
}
.contact .contact__section-container .contact__section-container_content-left {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 65%;
}
.contact .contact__section-container .contact__section-container_content-left h1 {
  font-family: var(--font-header);
  color: var(--muradiye-gold-color);
  margin-bottom: 5rem;
}
.contact .contact__section-container .contact__section-container_content-left h3,
.contact .contact__section-container .contact__section-container_content-left h4 {
  font-family: var(--font-family);
  color: var(--head-color-light);
}
.contact .contact__section-container .contact__section-container_content-left h3 {
  margin-bottom: 2rem;
  font-size: 1.25rem;
}
.contact .contact__section-container .contact__section-container_content-left h4 {
  font-size: 18px;
}
.contact .contact__section-container .contact__section-container_content-left p,
.contact .contact__section-container .contact__section-container_content-left small,
.contact .contact__section-container .contact__section-container_content-left a {
  font-family: var(--font-family);
  color: #7a8a8a;
}
.contact .contact__section-container .contact__section-container_content-left a:hover {
  color: var(--muradiye-gold-color);
}
.contact .contact__section-container .contact__section-container_content-right {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  width: 70%;
}
.contact .contact__section-container .contact__section-container_content-right img {
  width: 100%;
  opacity: 90%;
  filter: drop-shadow(10px 5px 6px #444);
}
.contact .contact__section-container .contact__section-container_content-img_container {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1440px) {
  .contact .contact__section-container {
    align-items: center;
  }
}
@media screen and (max-width: 770px) {
  .contact {
    padding: 0;
  }
  .contact .contact__section-container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .contact .contact__section-container .contact__section-container_content-right img {
    width: 80%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 520px) {
  .contact .contact__section-container {
    padding: 10rem 0;
  }
}/*# sourceMappingURL=contact.css.map */